html {
	scroll-behavior: smooth;
	/* background: #212121; */
}

body {
	margin: 0;
	/* font-family: "Rubik", sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* user-select: none; */
}

/* ---------- Scroll bar ---------- */
/* width */
::-webkit-scrollbar {
	width: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #a91010;
}