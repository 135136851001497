#distractor {
    position: absolute;
    top: calc(50% - 68px);
    left: calc(50% - 68px);
    border: 16px solid #333333;
    border-top: 16px solid #F23030;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}